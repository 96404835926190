var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vpn-ip-addr-wrapper" }, [
    _vm.public_ip
      ? _c("div", { staticClass: "ip-row public-ip" }, [
          _c("span", { staticClass: "lbl" }, [
            _vm._v(_vm._s(_vm.$t("widgets.gluetun-status.vpn-ip"))),
          ]),
          _c("span", { staticClass: "val" }, [_vm._v(_vm._s(_vm.public_ip))]),
        ])
      : _vm._e(),
    _vm.country
      ? _c("div", { staticClass: "ip-row" }, [
          _c("span", { staticClass: "lbl" }, [
            _vm._v(_vm._s(_vm.$t("widgets.gluetun-status.country"))),
          ]),
          _c("span", { staticClass: "val" }, [_vm._v(_vm._s(_vm.country))]),
        ])
      : _vm._e(),
    _vm.region
      ? _c("div", { staticClass: "ip-row" }, [
          _c("span", { staticClass: "lbl" }, [
            _vm._v(_vm._s(_vm.$t("widgets.gluetun-status.region"))),
          ]),
          _c("span", { staticClass: "val" }, [_vm._v(_vm._s(_vm.region))]),
        ])
      : _vm._e(),
    _vm.city
      ? _c("div", { staticClass: "ip-row" }, [
          _c("span", { staticClass: "lbl" }, [
            _vm._v(_vm._s(_vm.$t("widgets.gluetun-status.city"))),
          ]),
          _c("span", { staticClass: "val" }, [_vm._v(_vm._s(_vm.city))]),
        ])
      : _vm._e(),
    _vm.postal_code
      ? _c("div", { staticClass: "ip-row" }, [
          _c("span", { staticClass: "lbl" }, [
            _vm._v(_vm._s(_vm.$t("widgets.gluetun-status.post-code"))),
          ]),
          _c("span", { staticClass: "val" }, [_vm._v(_vm._s(_vm.postal_code))]),
        ])
      : _vm._e(),
    _vm.location
      ? _c("div", { staticClass: "ip-row" }, [
          _c("span", { staticClass: "lbl" }, [
            _vm._v(_vm._s(_vm.$t("widgets.gluetun-status.location"))),
          ]),
          _c("span", { staticClass: "val" }, [_vm._v(_vm._s(_vm.location))]),
        ])
      : _vm._e(),
    _vm.timezone
      ? _c("div", { staticClass: "ip-row" }, [
          _c("span", { staticClass: "lbl" }, [
            _vm._v(_vm._s(_vm.$t("widgets.gluetun-status.timezone"))),
          ]),
          _c("span", { staticClass: "val" }, [_vm._v(_vm._s(_vm.timezone))]),
        ])
      : _vm._e(),
    _vm.organization
      ? _c("div", { staticClass: "ip-row" }, [
          _c("span", { staticClass: "lbl" }, [
            _vm._v(_vm._s(_vm.$t("widgets.gluetun-status.organization"))),
          ]),
          _c("span", { staticClass: "val" }, [
            _vm._v(_vm._s(_vm.organization)),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }